import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, icp, children }) => {
  return (
    <div className="flex h-screen">
      <aside className="w-1/6 p-5 bg-background flex flex-col justify-between">
        <Link className="pt-5 font-bold font-sans no-underline text-5xl text-white" to="/">
          {title}
        </Link>
        <footer className="text-white text-opacity-50">
          <p><a href="https://beian.miit.gov.cn">{icp}</a></p>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer>
      </aside>
      <main className="w-5/6 my-5 ml-16 overflow-y-scroll ">
        <article className="w-4/5">{children}</article>
      </main>
    </div>
  )
}

export default Layout
